import styled from 'styled-components';
import {space, typography, layout} from 'styled-system'; // TODO: CRITICAL: remove styled-system calls
import Link from '@components/Link';

import {LogoWhite} from '../LogoWhite/index';

import {color, font} from '@design/templateFns';
export interface AddressProps {
  AddressLine1: string;
  AddressLine2: string;
  email: string;
  phone: string;
  fax: string;
}

export const Address = ({
  AddressLine1,
  AddressLine2,
  email,
  phone,
  fax,
}: AddressProps): JSX.Element => {
  return (
    <div>
      <Link href="/">
        <LogoSpacer>
          <LogoWhite />
        </LogoSpacer>
      </Link>

      <StyledLink
        display="block"
        fontSize={[1, 0, null, 1]}
        fontWeight={['400', null, null, '100']}
        lineHeight="20px"
        letterSpacing="-0.015em"
        pb="5px"
        maxWidth="200px"
      >
        {AddressLine1}
      </StyledLink>
      <StyledLink
        display="block"
        fontSize={[1, 0, null, 1]}
        fontWeight={['400', null, null, '100']}
        lineHeight="20px"
        letterSpacing="-0.015em"
        pb="22px"
        maxWidth="200px"
      >
        {AddressLine2}
      </StyledLink>
      <StyledLink
        display="block"
        fontSize={[1, 0, null, 1]}
        fontWeight={['400', null, null, '100']}
        lineHeight="20px"
        letterSpacing="-0.015em"
        pb="5px"
        maxWidth="200px"
      >
        {phone}
      </StyledLink>
      {fax && (
        <StyledLink
          display="block"
          fontSize={[1, 0, null, 1]}
          fontWeight={['400', null, null, '100']}
          lineHeight="20px"
          letterSpacing="-0.015em"
          pb="22px"
          maxWidth="200px"
        >
          {fax}
        </StyledLink>
      )}
      <StyledLink
        display="block"
        fontSize={[1, 0, null, 1]}
        fontWeight={['400', null, null, '100']}
        lineHeight="20px"
        letterSpacing="-0.015em"
        pb="10px"
        maxWidth="230px"
        href={`mailto: ${email}`}
      >
        {email}
      </StyledLink>
    </div>
  );
};

// IGNORE THIS STYLED COMPONENT SINCE THIS WILL BE DELETED AFTER
// RECEIVING LOGO FROM JACKIE/+DANA. IT'S A PLACEHOLDER
// (So it doesn't look so hideous)!
const StyledTitle = styled.h2`
  font-size: 27px;
  font-weight: 100;
  line-height: 33px;
  letter-spacing: 0em;
  text-transform: uppercase;
  margin-top: 0;

  @media (max-width: 1024px) {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 25px;
    font-weight: 300;
    line-height: 31px;
  }
`;
//----------------------------

const StyledLink = styled.a`
  font-size: 20px;
  ${typography}
  ${space}
  color: ${color('bg')};
  ${layout}
  ${font('body-sm-medium')} // #font-check xs->sm
  //Styled System Doesn't Support These Stylings
  cursor: pointer;
  text-decoration: none;
  //--------------------
`;
const LogoSpacer = styled.div`
  display: block;
  margin-bottom: 30px;
`;
