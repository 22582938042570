import {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

import Link from '@components/Link';
import {useRouter} from 'next/router';

import {BackButton} from './BackButton';
import {Search} from '../MiscComponents/index';
import {HamburgerMenu, Close} from '../NavSvgComponent/index';
import {Logo} from '../Logo/index';
import {RightArrowNoLine} from '../Arrow/index';
import {color, space, query, font} from '@design/templateFns';

import {NavigationProps} from './index';

export interface NavigationBottomProps extends NavigationProps {
  show: boolean;
  handleChangeLevel: (number) => void;
  menuLevel: number;
  handleCurrentParent: (string) => void;
}

interface LinkObject {
  label: string;
  url: string;
  links?: LinkObject[];
}

interface UtilLink {
  label: string;
  url: string;
  _key: string;
}

/****
 * MOBILE
 * */
const StyledMobileMenuWrapper = styled.div`
  display: block;
  padding: ${space('marginMobile')};
  background-color: ${color('bg')};
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  position: ${({isSticky}) => (isSticky ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 3;

  @media (${query.atLeast('desktop')}) {
    display: none;
  }
`;

const StyledMobileFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1px;
`;

/** Logo */
const StyledLogoWrapper = styled.div`
  svg {
    width: 100px;
  }

  @media (${query.atLeast('tablet')}) {
    svg {
      width: 120px;
    }
  }
`;

/** Right Sect */
const StyledMobileRightSection = styled.div`
  display: flex;
  align-items: center;
`;

const HamburgerMenuWrapper = styled.div`
  .close-icon {
    transform: scale(1.2);
    cursor: pointer;
  }
`;

const StyledSearchWrapper = styled.div`
  margin-right: ${space('marginMobilNav')};
`;

/*** MOBILE NAVIGATION BOTTOM */
const StyledMobileBottomWrapper = styled.div`
  position: relative;
`;

const StyledMobileBottomBody = styled.div`
  background-color: ${color('bg')};
  transition: transform 0.4s ease-in-out;
  position: fixed;
  width: 100%;
  top: 76px;
  left: 0;
  bottom: 0;
  transform: translateX(${({show}) => (show ? '0px' : '100%')});
  z-index: 2;
  box-shadow: inset 0px 8px 18px 0px rgb(0 0 0 / 16%);

  padding-top: ${space('marginMobilNav')};
`;

const StyledMobileBottomBodyAdditional = styled(StyledMobileBottomBody)`
  top: 0;
`;

const StyledMobileBottomBodyLinkList = styled.ul`
  list-style-type: none;
  padding: 0 ${space('marginMobile')};
  margin: 0;
`;

const StyledMobileBottomBodyLink = styled.li`
  ${font('headline-xxs')}
  padding: ${space('marginMobilNav')} 0;
  border-bottom: 0.5px solid rgba(88, 88, 90, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #212121;
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;

  a {
    text-decoration: none;
    border: none;
    color: #212121;

    > li {
      border: none;
    }
  }
`;

const StyledMobileBottomBodyLinkWithSublinks = styled.div`
  color: #212121;
  padding: 0;
`;

const StyledMobileBottomBodyLinkA = styled.div`
  text-decoration: none;
`;

const StyledMobileBottomAdditionalLinks = styled.li``;

const StyledMobileBottomAdditionalLinksRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMobileBottomAdditionalLinksRowCell = styled.div`
  display: flex;
  align-items: center;
  ${({border}) => border && `border-right: 1px solid #58585A;`}
  border-bottom: none;

  span {
    margin-right: ${space('unit')};
  }
  .add-right-margin {
    margin-right: ${space('margin')};
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledMobileBottomBottomBodyLinkArrowWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledMobileUtilLink = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 20px;
`;

const StyledUtilItem = styled.a`
  ${font('body-sm-regular')} // #font-check xs->sm
  margin-top: ${space('marginMobile')};
  text-decoration: none;
  display: block;
  color: ${color('fg')};
`;

export const MobileMenu = ({
  links,
  details,
  utilLinks,
  search,
}: NavigationProps): JSX.Element => {
  const [isSticky, setIsSticky] = useState(false); // for sticky behavior
  const [showNavBottomMobile, handleShowNavBottomMobile] = useState(false);
  const [menuLevel, handleMenuLevel] = useState(1);
  const [currentParent, handleCurrentParent] = useState('');

  // Nav ref
  const navRef = useRef(null);

  // Function to toggle sticky state
  const handleScroll = () => {
    window.scrollY > navRef.current.getBoundingClientRect().bottom
      ? setIsSticky(true)
      : setIsSticky(false);
  };

  // Event listeners for handling sticky behavior
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // <Link /> between dynamic routes
  // doesn't seem to reset state
  // thus leaving the mobile menu open.
  // This forces a state reset when changing routes.
  const dynamicRoute = useRouter().asPath;
  useEffect(() => {
    handleShowNavBottomMobile(false);
    handleMenuLevel(1);
    handleCurrentParent('');
  }, [dynamicRoute]);

  const handleHamburgerClick = (e) => {
    e.preventDefault();
    resetLevelDefault();
    handleShowNavBottomMobile(!showNavBottomMobile);
  };

  const handleChangeLevel = (change: 1 | -1) => {
    handleMenuLevel(menuLevel + change);
  };

  const resetLevelDefault = () => {
    handleMenuLevel(1);
  };

  return (
    <StyledMobileMenuWrapper ref={navRef} isSticky={isSticky}>
      {/*** MOBILE */}
      <StyledMobileFlex>
        {/*** TEMP - Needs to be replaced by SVG Logo */}
        {menuLevel === 1 ? (
          <StyledLogoWrapper>
            <Link href="/">
              <Logo />
            </Link>
          </StyledLogoWrapper>
        ) : (
          <BackButton
            menuLevel={menuLevel}
            handleChangeLevel={handleChangeLevel}
            parent={currentParent}
          />
        )}
        <StyledMobileRightSection>
          <StyledSearchWrapper>
            <Search
              color="#01617a"
              hoverColor="#01617a"
              link={search}
              size="18"
            />
          </StyledSearchWrapper>
          <HamburgerMenuWrapper onClick={handleHamburgerClick}>
            {showNavBottomMobile ? (
              <Close color="#192630" size="21" />
            ) : (
              <HamburgerMenu color="#192630" hoverColor="#1B76B0" size="18" />
            )}
          </HamburgerMenuWrapper>
        </StyledMobileRightSection>
      </StyledMobileFlex>
      <StyledMobileBottomWrapper>
        <NavigationMobileBottom
          links={links}
          details={details}
          show={showNavBottomMobile}
          handleChangeLevel={handleChangeLevel}
          menuLevel={menuLevel}
          handleCurrentParent={handleCurrentParent}
          search={search}
          utilLinks={utilLinks}
        />
      </StyledMobileBottomWrapper>
    </StyledMobileMenuWrapper>
  );
};

export const NavigationMobileBottom = ({
  links,
  utilLinks,
  show,
  handleChangeLevel,
  menuLevel,
  handleCurrentParent,
}: NavigationBottomProps): JSX.Element => {
  const router = useRouter();
  const [subMenuItems, handleSubMenuItems] = useState({});

  // Render Menu List
  const renderItems = (links: LinkObject[]) => {
    if (links && links.length > 0) {
      return links.map((link, index) => {
        const hasSublinks = link.links && link.links.length > 0;

        return hasSublinks ? (
          /** Has  sublinks */
          <StyledMobileBottomBodyLink key={index}>
            <Link key={index} href={link.url}>
              <StyledMobileBottomBodyLinkWithSublinks key={link.label}>
                {link.label}
              </StyledMobileBottomBodyLinkWithSublinks>
            </Link>
            <StyledMobileBottomBottomBodyLinkArrowWrapper
              onClick={() =>
                handleItemClickWithSubitems(link.links, link.label)
              }
            >
              <RightArrowNoLine size={8} />
            </StyledMobileBottomBottomBodyLinkArrowWrapper>
          </StyledMobileBottomBodyLink>
        ) : (
          /** Has no sublinks */
          <Link key={index} href={link.url} passHref={true}>
            <StyledMobileBottomBodyLinkA>
              <StyledMobileBottomBodyLink key={link.label} fontSize={2}>
                {link.label}
              </StyledMobileBottomBodyLink>
            </StyledMobileBottomBodyLinkA>
          </Link>
        );
      });
    } else {
      return null;
    }
  };

  // Render Submenu
  const renderSubmenuItems = (links: LinkObject[]) => {
    if (links && links.length > 0) {
      return renderItems(links);
    } else {
      return null;
    }
  };

  // Assigns menu item
  const handleItemClickWithSubitems = (links: LinkObject[], label: string) => {
    handleChangeLevel(1);

    let menuObj = subMenuItems;

    menuObj[menuLevel] = links;

    handleCurrentParent(label);
    handleSubMenuItems(menuObj);
  };

  return (
    <>
      <StyledMobileBottomBody show={show} fontFamily="body">
        {/** Primary Nav Links */}
        <StyledMobileBottomBodyLinkList fontFamily="body">
          {renderItems(links)}
        </StyledMobileBottomBodyLinkList>
        <StyledMobileBottomBodyAdditional show={menuLevel >= 2}>
          <StyledMobileBottomBodyLinkList fontFamily="body">
            {renderSubmenuItems(subMenuItems[1])}
          </StyledMobileBottomBodyLinkList>
        </StyledMobileBottomBodyAdditional>
        <StyledMobileBottomBodyAdditional show={menuLevel >= 3}>
          <StyledMobileBottomBodyLinkList fontFamily="body">
            {renderSubmenuItems(subMenuItems[2])}
          </StyledMobileBottomBodyLinkList>
        </StyledMobileBottomBodyAdditional>
        <StyledMobileUtilLink>
          {utilLinks.map(
            (
              links,
              index, // TODO: make standard links
            ) => (
              <StyledUtilItem href={links.url} key={index}>
                {links.label}
              </StyledUtilItem>
            ),
          )}
        </StyledMobileUtilLink>
      </StyledMobileBottomBody>
    </>
  );
};
