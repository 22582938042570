// mapLink
// Handles mapping of pageLink items
// to account for internal links, external links, and empty data.
export type mappableLink = {
  slug?: {current?: string; hardLoad?: boolean};
  url?: string;
};

export const mapLink = (link?: mappableLink): string => {
  let url = link?.slug?.current
    ? `/${link.slug.current}`
    : `${link?.url || ''}`;
  if (link?.slug?.hardLoad) {
    url = url + '?hl=1'; // TODO more robust query param injection
  }
  return url;
};
