import React from 'react';
import Head from 'next/head';
import {SimpleComponent} from '@util/types';
import theme from '@design/theme';
import {ThemeProvider} from 'styled-components';

const consentNoticeFunction = `function OptanonWrapper() {}`;

const gtmId = 'GTM-TLFNDDG';

const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TLFNDDG');`;

const getCanonicalUrl = (slug: {current: string}) => {
  if (!slug) {
    return undefined;
  }
  return (
    // TODO: derive from project cfg file, once project cfg file works
    (process.env.SITE_URL || 'https://missing-env-siteurl.example.com/') +
      slug?.current || ''
  );
};

interface propsPage {
  metatitle?: string;
  description?: string;
  openGraphImage?: any;
  slug?: {current: string};
}

const Page: SimpleComponent<propsPage> = (props) => (
  <>
    <Head>
      {/*{`<!-- CookiePro Cookies Consent Notice start for koppers.com  -->*/}
      <script
        type="text/javascript"
        src="https://cookie-cdn.cookiepro.com/consent/a5dcdb4a-9173-41a0-a8eb-b9685f2f6886/OtAutoBlock.js"
      ></script>
      <script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="a5dcdb4a-9173-41a0-a8eb-b9685f2f6886"
      ></script>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{__html: consentNoticeFunction}}
      />
      {/*{`<!-- CookiePro Cookies Consent Notice end for koppers.com -->*/}
      <script dangerouslySetInnerHTML={{__html: gtmScript}} />
      <link rel="stylesheet" href="https://use.typekit.net/zkv0qdd.css" />
      {/* <link rel="stylesheet" href="https://use.typekit.net/ypr6nez.css" /> */}
      <title>{props.metatitle}</title>
      <meta property="og:title" content={props.metatitle} key="title" />
      <meta
        property="og:image"
        content={props.openGraphImage ? props.openGraphImage.asset.url : ''}
      />
      <meta name="description" content={props.description} key="description" />
      <meta
        name="google-site-verification"
        content="yV137ynKlV9lZ8YbW3f-H4wB0tiLRN8bhByOD1h12NI"
      />
      <link
        rel="canonical"
        href={getCanonicalUrl(props.slug)}
        key="canonical"
      />
    </Head>

    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-TLFNDDG"
        height="0"
        width="0"
        style={{display: 'none', visibility: 'hidden'}}
      ></iframe>
    </noscript>
    <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
  </>
);

export default Page;
