import {TypeOf} from 'yup';
import {ThemeFontDef} from './types';

const colors = {
  blue: '#01617a',
  forest: '#1f513f',
  midnight: '#192630',
  marigold: '#ffbb38',
  ivory: '#f7f3e8',
  white: '#ffffff',
  black: '#111111',
  charcoal: '#282828',
  shadow: 'rgba(0, 0, 0, 0.2)',
  transparent: '',
};

const fonts = {
  'headline-xxxl': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '64px',
    lineHeight: '104%',
    letterSpacing: '-0.01em',
  } as ThemeFontDef,
  'headline-xxl': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '44px',
    lineHeight: '112%',
    letterSpacing: '-0.005em',
  } as ThemeFontDef,
  'headline-xl': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '34px',
    lineHeight: '112%',
  } as ThemeFontDef,
  'headline-lg': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '30px',
    lineHeight: '112%',
  } as ThemeFontDef,
  'headline-md': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '28px',
    lineHeight: '112%',
  } as ThemeFontDef,
  'headline-sm': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '120%',
  } as ThemeFontDef,
  'headline-xs': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '120%',
  } as ThemeFontDef,
  'headline-xxs': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '120%',
  } as ThemeFontDef,
  'headline-xxxs': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '120%',
  } as ThemeFontDef,

  'eyebrow-lg': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '130%',
    letterSpacing: '0.12em',
  } as ThemeFontDef,
  'eyebrow-sm': {
    fontFamily: 'azo-sans-web',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '104%',
    letterSpacing: '0.12em',
  } as ThemeFontDef,

  'body-lg-regular': {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '144%',
    letterSpacing: '-0.01em',
  } as ThemeFontDef,
  'body-lg-medium': {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '144%',
    letterSpacing: '-0.01em',
  } as ThemeFontDef,
  'body-md-regular': {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '144%',
    letterSpacing: '-0.01em',
  } as ThemeFontDef,
  'body-md-medium': {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '144%',
    letterSpacing: '-0.01em',
  } as ThemeFontDef,
  'body-sm-regular': {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '134%',
    letterSpacing: '-0.01em',
  } as ThemeFontDef,
  'body-sm-medium': {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '134%',
    letterSpacing: '-0.01em',
  } as ThemeFontDef,
};

// NOT SUPPORTED YET (TODO: suppoort)
// Overrides to theme defaults,
// like spacing, border styles, corner radii, named drop-shadow variants etc.
// see `./theme.ts` for all overridable properties
const overrides: any = {}; // TODO: properly derive this type

const theme = {
  colors,
  fonts,
  overrides,
};

export type BrandTheme = typeof theme;
export type BrandPallete = BrandTheme['colors'];
export type BrandFonts = BrandTheme['fonts'];

export type BrandColorName = keyof BrandPallete;
export type BrandFontName = keyof BrandFonts;

export default theme;
