/** deprecated */
import {solid, secondary, category, outlined, text} from './variants/buttons';
import colors, {ThemeColorList} from './colors';

export type ThemeSpaceName =
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'x5'
  | 'x6'
  | 'x7'
  | 'x8'
  | 'x10'
  | 'x12'
  | 'margin'
  | 'marginMobile'
  | 'marginWide'
  | 'marginTablet';

export type ThemeBreakpointName = 'tablet' | 'desktop' | 'max';

// combined array scale with named keys
export type ThemeSpaceScale = number[] & {
  [index in ThemeSpaceName]: number;
};

// combined array scale with named keys
export type ThemeBreakpointScale = string[] & {
  [index in ThemeBreakpointName]: string;
};

// export type ThemeLetterSpacingName =
//   | 'narrow'
//   | 'xs'
//   | 'sm'
//   | 'md'
//   | 'standard'
//   | 'wide'
//   | 'xw'
//   | 'xxw';

// combined array scale with named keys
// export type ThemeLetterSpacingScale = number[] & {
//   [index in ThemeLetterSpacingName]: number;
// };

export type ThemeFontSizeName =
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'x5'
  | 'x6'
  | 'x7'
  | 'x8'
  | 'x9';

export type ThemeFontSizeScale = number[] & {
  [index in ThemeFontSizeName]: number;
};

export type Theme = {
  breakpoints: ThemeBreakpointScale;
  buttons: any;
  buttonVariants: any;
  buttonSizes: any;
  colors: ThemeColorList;
  lineHeights: {
    // maybe lineheights are not a good thing to quantize
    body: number;
    heading: number;
    headingSecondary: number;
  };
  // letterSpacings: ThemeLetterSpacingScale;
  fonts: {
    // this really should be called something like fontFamilies
    body: string;
    headline: string;
    eyebrow: string;
    subhead: string;
    btnLabel: string;
  };
  fontWeights: {
    bold: number;
    semiBold: number;
    medium: number;
    regular: number;
    light: number;
  };
  fontSizes: ThemeFontSizeScale;
  fontSize: ThemeFontSizeScale;
  space: ThemeSpaceScale;
  spaces: ThemeSpaceScale;
};

//             0  1   2   3   4   5   6   7   8   9  10
const space = [0, 8, 16, 32, 40, 48, 56, 64, 72, 80, 96] as ThemeSpaceScale;
space.sm = space[1];
space.md = space[2];
space.lg = space[3];
space.xl = space[4];
space.x5 = space[5];
space.x6 = space[6];
space.x7 = space[7];
space.x8 = space[8];
space.x10 = space[9];
space.x12 = space[10];
space.margin = 80;
space.marginMobile = 20;
space.marginWide = 200;
space.marginTablet = 64;

const breakpoints = ['768px', '1024px', '1440px'] as ThemeBreakpointScale;
breakpoints.tablet = breakpoints[0];
breakpoints.desktop = breakpoints[1];
breakpoints.max = breakpoints[2];

// const letterSpacing = [
//   -3, -1.5, -1, -0.5, 0, 1, 4, 12,
// ] as ThemeLetterSpacingScale;
// letterSpacing.xs = space[1];
// letterSpacing.sm = space[2];
// letterSpacing.md = space[3];
// letterSpacing.standard = space[4];
// letterSpacing.wide = space[5];
// letterSpacing.xw = space[6];
// letterSpacing.xxw = space[7];

//                [ 0   1   2   3   4   5   6   7   8]
const fontSizes = [14, 16, 18, 24, 27, 42, 56, 64, 72] as ThemeFontSizeScale;
fontSizes.sm = fontSizes[0];
fontSizes.md = fontSizes[1];
fontSizes.lg = fontSizes[2];
fontSizes.xl = fontSizes[3];
fontSizes.x5 = fontSizes[4];
fontSizes.x6 = fontSizes[5];
fontSizes.x7 = fontSizes[6];
fontSizes.x8 = fontSizes[7];
fontSizes.x9 = fontSizes[8];

const kmcButtonVariants = {
  primary: {
    color: colors.secondary,
    backgroundColor: colors.red,
    activeColor: colors.pressedRed,
    hoverColor: colors.hoverRed,
  },
  secondary: {
    color: colors.secondary,
    backgroundColor: colors.primary,
    activeColor: colors.pressedRed,
    hoverColor: colors.hoverRed,
  },
  text: {
    color: colors.red,
  },
  link: {
    color: colors.secondary,
  },
  utilSecondaryFooterLink: {
    color: colors.grey50,
    activeColor: colors.lightGrey,
    hoverColor: colors.errorRed,
    styles: {
      textTransform: 'initial',
      fontWeight: 'normal',
      justifyContent: 'left',
      fontSize: '12px!important',
      textAlign: 'left',
      width: '100%',
      height: '47px',
      letterSpacing: '0.02rem',
    },
  },
  utilFooterLink: {
    color: colors.lightGrey,
    activeColor: colors.grey50,
    hoverColor: colors.errorRed,
    styles: {
      textTransform: 'initial',
      fontWeight: 'normal',
      justifyContent: 'left',
      fontSize: '18px!important',
      textAlign: 'left',
      width: '100%',
      height: '47px',
    },
  },
  footerLink: {
    color: colors.lightGrey,
    activeColor: colors.grey50,
    hoverColor: colors.errorRed,
    styles: {
      justifyContent: 'left',
      fontWeight: 'normal',
      fontSize: '15px!important',
      height: '47px',
      maxWidth: '240px',
      width: '100%',
      lineHeight: '18px',
      textAlign: 'left',
    },
  },
  footerHeaderLink: {
    color: colors.secondary,
    activeColor: colors.grey50,
    hoverColor: colors.errorRed,
    styles: {
      justifyContent: 'left',
      height: '47px',
      maxWidth: '240px',
      width: '100%',
      lineHeight: '23px',
      textAlign: 'left',
    },
  },
  outline: {
    color: colors.red,
    backgroundColor: colors.secondary,
    activeColor: colors.pressedRed,
    hoverColor: colors.hoverRed,
    styles: {
      border: '2px solid ' + colors.red,
      borderRadius: '8px',
    },
  },
  icon: {
    color: colors.secondary,
    backgroundColor: colors.grey92,
    activeColor: colors.primary,
    hoverColor: colors.grey50,
  },
  dealerBtn: {
    color: colors.secondary,
    backgroundColor: colors.red,
    activeColor: colors.pressedRed,
    hoverColor: colors.hoverRed,
    styles: {
      borderRadius: 0,
      height: '87px',
    },
  },
  mobileDealerBtn: {
    color: colors.secondary,
    backgroundColor: colors.red,
    activeColor: colors.pressedRed,
    hoverColor: colors.hoverRed,
    styles: {
      paddingLeft: '16px',
      paddingRight: '14px',
      paddingTop: '0',
      paddingBottom: '0',
      marginTop: '-11px',
      marginLeft: '-7px',
      fontWeight: 'normal',
      height: '40px',
      width: '127px',
    },
  },
};

const kmcButtonSizes = {
  large: {
    width: '280px',
    height: '64px',
    fontSize: 'calc(100% + 0.285714vw)',
    maxFontSize: '30px',
  },
  medium: {
    width: '212px',
    height: '50px',
    fontSize: 'calc(100% + 0.385714vw)',
    maxFontSize: '20px',
  },
  small: {
    width: '165px',
    height: '40px',
    fontSize: 'calc(100% + 0.085714vw)',
    maxFontSize: '16px',
  },
  icon: {
    width: '48px',
    height: '48px',
    fontSize: '24px',
    maxFontSize: '24px',
  },
};

const theme: Theme = {
  breakpoints,
  buttons: {
    solid,
    secondary,
    category,
    outlined,
    text,
  },
  buttonVariants: kmcButtonVariants,
  buttonSizes: kmcButtonSizes,
  colors,
  lineHeights: {
    body: 1.5,
    heading: 1.25,
    headingSecondary: 1.125,
  },
  fonts: {
    headline: 'trade-gothic-next',
    body: 'trade-gothic-next',
    eyebrow: 'trade-gothic-next',
    subhead: 'trade-gothic-next',
    btnLabel: 'trade-gothic-next-condensed',
  },
  fontSizes,
  fontSize: fontSizes, // renamed fontSize to fontSizes
  fontWeights: {
    bold: 700,
    semiBold: 600,
    medium: 500,
    regular: 400,
    light: 300,
  },
  // letterSpacings: letterSpacing,
  space,
  spaces: space, // renamed spaces to space ()
};

// ADDITIONAL TYPING
export type ThemeLineHeights = keyof typeof theme.lineHeights;
export type ThemeFontWeights = keyof typeof theme.fontWeights;
export type ThemeFonts = keyof typeof theme.fonts;

export default theme;
