import React from 'react';

import styled, {css} from 'styled-components';

interface LogoProps {
  color?: string;
  size?: number;
  hoverColor?: string;
}

export const LogoWhite = ({
  color = '#000',
  hoverColor = '#000',
  size = 150,
}: LogoProps) => {
  return (
    <StyledSVG width="136" height="49" viewBox="0 0 136 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4890_10729)">
      <path d="M91.9735 40.4912H91.5762C96.1451 45.5899 102.833 48.8345 110.315 48.8345C113.957 48.8345 117.467 48.0399 120.579 46.6493H107.402C101.376 46.6493 95.9464 44.3318 91.9735 40.4912Z" fill="#5AB125"/>
      <path d="M107.402 2.35066H120.579C117.467 0.960122 113.957 0.165527 110.315 0.165527C102.833 0.165527 96.1451 3.41012 91.5762 8.50877H91.9735C95.9464 4.73445 101.376 2.35066 107.402 2.35066Z" fill="#5AB125"/>
      <path d="M121.771 26.6522L134.882 27.844C135.015 27.0494 135.081 26.1886 135.081 25.394L121.771 26.3873C121.771 26.4535 121.771 26.5197 121.771 26.6522Z" fill="#5AB125"/>
      <path d="M121.24 30.4924L134.02 31.6843C134.285 30.9559 134.483 30.1613 134.616 29.3667L121.306 30.2275C121.306 30.2937 121.24 30.3599 121.24 30.4924Z" fill="#5AB125"/>
      <path d="M132.498 35.3928C132.895 34.6644 133.226 33.8698 133.558 33.0752L120.314 34.0022C120.314 34.0684 120.248 34.1347 120.248 34.2671L132.498 35.3928Z" fill="#5AB125"/>
      <path d="M130.312 38.9022C130.842 38.1739 131.372 37.4455 131.835 36.6509L118.724 37.5779C118.658 37.6441 118.658 37.7103 118.592 37.8428L130.312 38.9022Z" fill="#5AB125"/>
      <path d="M116.143 41.2195L127.267 42.2128C127.995 41.4844 128.724 40.756 129.386 39.9614L116.341 40.9547C116.275 41.0871 116.209 41.1533 116.143 41.2195Z" fill="#5AB125"/>
      <path d="M122.964 45.4574C124.089 44.7952 125.149 44.0669 126.142 43.2061L112.7 44.2655C112.568 44.3317 112.435 44.4642 112.303 44.5304L122.964 45.4574Z" fill="#5AB125"/>
      <path d="M135.081 23.6722C135.081 22.8114 135.015 22.0168 134.882 21.2222L121.771 22.4141C121.771 22.4803 121.771 22.5465 121.771 22.6789L135.081 23.6722Z" fill="#5AB125"/>
      <path d="M134.616 19.6994C134.483 18.9048 134.285 18.1102 134.02 17.3818L121.24 18.5737C121.24 18.6399 121.24 18.7062 121.306 18.8386L134.616 19.6994Z" fill="#5AB125"/>
      <path d="M133.557 15.9914C133.226 15.1968 132.895 14.4022 132.498 13.6738L120.182 14.8657C120.182 14.9319 120.248 14.9982 120.248 15.1306L133.557 15.9914Z" fill="#5AB125"/>
      <path d="M131.835 12.4154C131.372 11.6208 130.842 10.8924 130.312 10.1641L118.592 11.2897C118.658 11.356 118.658 11.4222 118.724 11.5546L131.835 12.4154Z" fill="#5AB125"/>
      <path d="M129.386 9.03846C128.724 8.24387 127.995 7.51549 127.267 6.78711L116.143 7.78035C116.209 7.84657 116.275 7.979 116.341 8.04522L129.386 9.03846Z" fill="#5AB125"/>
      <path d="M126.142 5.79402C125.149 4.99943 124.089 4.20483 122.964 3.60889L112.303 4.4697C112.435 4.53591 112.568 4.66835 112.7 4.73456L126.142 5.79402Z" fill="#5AB125"/>
      <path d="M20.7919 10.098H17.15C17.15 10.098 4.56892 22.0169 3.70811 22.8777V10.098H0V39.5642H3.70811V25.9898C4.56892 26.9169 16.8189 39.498 16.8189 39.498H21.6527C21.6527 39.498 7.41622 24.7317 7.01892 24.3344C7.41622 24.0034 21.9176 10.0317 21.9176 10.0317H21.2554H20.7919V10.098Z" fill="white"/>
      <path d="M19.4671 24.7978C19.4671 22.6127 20.5265 20.3613 22.3144 18.9046C23.6387 17.7789 25.2279 17.2492 26.9495 17.2492C31.1211 17.2492 34.5644 20.6262 34.5644 24.7978C34.5644 29.2343 31.3198 32.6776 27.0819 32.6776C22.7779 32.6113 19.4671 29.2343 19.4671 24.7978ZM19.4671 17.1167C17.3481 19.1694 16.1562 21.8843 16.1562 24.7978C16.1562 31.0222 20.7914 35.7235 26.9495 35.7235C30.1941 35.7235 32.909 34.5316 35.0279 32.214C36.9481 30.1613 37.8752 27.7776 37.8752 24.7978C37.8752 21.8843 36.7495 19.2357 34.6306 17.1167C32.5117 15.064 29.9954 14.0708 27.0157 14.0708C24.036 14.0708 21.5198 15.064 19.4671 17.1167Z" fill="white"/>
      <path d="M46.219 17.6466C49.265 17.6466 50.4569 18.5075 50.4569 20.825C50.4569 23.0102 49.265 23.871 46.3515 23.871H43.438V17.6466H46.219ZM40.5907 14.4683H40.1934V35.3926H43.438V27.1156H46.0866C48.5366 27.1156 49.9934 26.7845 51.1853 25.9899C52.9069 24.8642 53.9001 22.8777 53.9001 20.6926C53.9001 18.375 52.8407 16.4547 50.8542 15.3291C49.5961 14.6007 47.8744 14.4683 45.7555 14.4683H40.5907Z" fill="white"/>
      <path d="M61.7798 17.6466C64.8257 17.6466 66.0176 18.5075 66.0176 20.825C66.0176 23.0102 64.8257 23.871 61.9122 23.871H58.9987V17.6466H61.7798ZM56.2176 14.4683H55.8203V35.3926H59.0649V27.1156H61.7136C64.1636 27.1156 65.6203 26.7845 66.8122 25.9899C68.5338 24.8642 69.5271 22.8777 69.5271 20.6926C69.5271 18.375 68.4676 16.4547 66.4811 15.3291C65.223 14.6007 63.5014 14.4683 61.3825 14.4683H56.2176Z" fill="white"/>
      <path d="M82.7035 14.4683H71.248V35.3264H82.9021V32.148H74.4926V26.4534H82.9021V23.275H74.4926V17.6466H82.9021V14.4683H82.7035Z" fill="white"/>
      <path d="M86.2801 14.4681H85.8828V35.3924H89.1274V17.6465H91.3788C93.1666 17.6465 94.4247 17.6464 95.3517 18.441C96.1463 19.1694 96.5436 20.1627 96.5436 21.3546C96.5436 23.2748 95.0206 24.5992 92.9017 24.5992H89.8558L96.6098 35.3262H100.45C100.45 35.3262 95.5504 27.5789 95.2855 27.1154C98.0666 26.4532 99.9206 24.1356 99.9206 21.2883C99.9206 19.037 98.795 16.8519 97.0733 15.66C95.5504 14.6005 93.9612 14.4019 91.9085 14.4019L86.2801 14.4681Z" fill="white"/>
      <path d="M101.377 19.8981C101.377 23.0102 104.29 24.5994 106.807 25.9238C108.727 26.9832 110.581 27.9102 110.581 29.367C110.581 31.4859 109.124 32.744 106.74 32.744C105.151 32.744 103.827 32.413 102.635 31.6846L101.774 31.1548L101.443 34.6643L101.907 34.863C102.9 35.2603 104.489 35.79 106.674 35.79C110.25 35.79 113.892 33.8697 113.892 29.6319C113.892 26.1224 110.912 24.5332 108.33 23.2089C106.409 22.2157 104.754 21.3548 104.754 19.8981C104.754 17.3819 107.601 17.1832 108.462 17.1832C109.786 17.1832 111.045 17.4481 111.773 17.8454L112.567 18.2427L113.163 15.0643L112.634 14.8657C110.912 14.2035 108.992 14.2035 108.462 14.2035C104.224 14.1373 101.377 16.5211 101.377 19.8981Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_4890_10729">
      <rect width="135.743" height="49" fill="white"/>
      </clipPath>
      </defs>
    </StyledSVG>
  );
};

const StyledSVG = styled.svg`
  ${(props: {color: string; hoverColor: string}) =>
    css`
      path {
        stroke: ${props.color};
        fill: ${props.color};
        transition: ease-in 0.2s;
      }
      &:hover {
        path {
          stroke: ${props.hoverColor};
          fill: ${props.hoverColor};
        }
      }
    `}
`;
