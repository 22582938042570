import React from 'react';
import styled, {css} from 'styled-components';

export interface SocialComponentsProps {
  color: string;
  hoverColor: string;
  link: string;
  size: string;
}

export const Facebook = ({
  color = 'blue',
  hoverColor = 'black',
  link = 'https://www.facebook.com',
  size = '36',
}: SocialComponentsProps): JSX.Element => {
  return (
    <>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span>
            <StyledSVG
              hoverColor={hoverColor}
              color={color}
              width={size}
              height={size}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 38"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 18.1C0 27.05 6.5 34.492 15 36V23h-4.5v-5H15v-4c0-4.5 2.9-7 7-7 1.3 0 2.7.2 4 .4V12h-2.3c-2.2 0-2.7 1.1-2.7 2.5V18h4.8l-.8 5h-4v13c8.5-1.509 15-8.949 15-17.9C36 8.146 27.9 0 18 0S0 8.145 0 18.1z"
              />
            </StyledSVG>
          </span>
        </a>
      ) : (
        <span>
          <svg
            width={size}
            height={size}
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 40 38"
          >
            <path
              fill={color}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 18.1C0 27.05 6.5 34.492 15 36V23h-4.5v-5H15v-4c0-4.5 2.9-7 7-7 1.3 0 2.7.2 4 .4V12h-2.3c-2.2 0-2.7 1.1-2.7 2.5V18h4.8l-.8 5h-4v13c8.5-1.509 15-8.949 15-17.9C36 8.146 27.9 0 18 0S0 8.145 0 18.1z"
            />
          </svg>
        </span>
      )}
    </>
  );
};

export const Twitter = ({
  color = '#204568',
  hoverColor = 'black',
  link = 'https://www.twitter.com',
  size = '37',
}: SocialComponentsProps): JSX.Element => {
  return (
    <>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span>
            <StyledSVG
              hoverColor={hoverColor}
              color={color}
              width={size}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 38"
            >
              <path d="M19.5.625C9.111.625.687 8.853.687 19S9.111 37.375 19.5 37.375 38.313 29.147 38.313 19 29.889.625 19.5.625zm9.04 13.851c.014.193.014.394.014.59 0 6.022-4.695 12.957-13.274 12.957-2.646 0-5.098-.75-7.164-2.042.378.04.739.057 1.125.057 2.184 0 4.191-.722 5.791-1.944-2.05-.04-3.77-1.353-4.359-3.158.718.102 1.365.102 2.104-.082a4.681 4.681 0 01-2.684-1.584 4.488 4.488 0 01-1.049-2.887v-.057c.617.34 1.344.55 2.104.578a4.598 4.598 0 01-1.526-1.64 4.474 4.474 0 01.081-4.447 13.187 13.187 0 004.29 3.38 13.487 13.487 0 005.326 1.382c-.659-3.096 1.71-5.602 4.557-5.602 1.343 0 2.553.55 3.405 1.435a9.349 9.349 0 002.96-1.095 4.555 4.555 0 01-2.049 2.506 9.397 9.397 0 002.688-.71 9.656 9.656 0 01-2.34 2.363z" />
            </StyledSVG>
          </span>
        </a>
      ) : (
        <span>
          <svg
            width={size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 38"
          >
            <path
              d="M19.5.625C9.111.625.687 8.853.687 19S9.111 37.375 19.5 37.375 38.313 29.147 38.313 19 29.889.625 19.5.625zm9.04 13.851c.014.193.014.394.014.59 0 6.022-4.695 12.957-13.274 12.957-2.646 0-5.098-.75-7.164-2.042.378.04.739.057 1.125.057 2.184 0 4.191-.722 5.791-1.944-2.05-.04-3.77-1.353-4.359-3.158.718.102 1.365.102 2.104-.082a4.681 4.681 0 01-2.684-1.584 4.488 4.488 0 01-1.049-2.887v-.057c.617.34 1.344.55 2.104.578a4.598 4.598 0 01-1.526-1.64 4.474 4.474 0 01.081-4.447 13.187 13.187 0 004.29 3.38 13.487 13.487 0 005.326 1.382c-.659-3.096 1.71-5.602 4.557-5.602 1.343 0 2.553.55 3.405 1.435a9.349 9.349 0 002.96-1.095 4.555 4.555 0 01-2.049 2.506 9.397 9.397 0 002.688-.71 9.656 9.656 0 01-2.34 2.363z"
              fill={color}
            />
          </svg>
        </span>
      )}
    </>
  );
};

export const LinkedIn = ({
  color = '#204568',
  hoverColor = 'black',
  link = 'https://www.linkedin.com',
  size = '36',
}: SocialComponentsProps): JSX.Element => {
  return (
    <>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span>
            <StyledSVG
              hoverColor={hoverColor}
              color={color}
              width={size}
              height={size}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 37 36"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18zm-2.537-9.645l.002.045c1.458 0 2.915 0 4.373-.002l-.002-.027c-.002-.023-.004-.045-.004-.068v-2.398-4.795a2.8 2.8 0 01.098-.819 2.56 2.56 0 01.535-.938c.176-.202.373-.381.608-.517.267-.155.557-.24.863-.27.31-.03.62-.017.924.056.52.124.908.42 1.168.88.175.307.271.64.331.986.061.352.08.708.08 1.064V26.4h4.357l.001-.022a.843.843 0 00.003-.06v-7.313c0-.428-.012-.856-.067-1.282a7.105 7.105 0 00-.313-1.402c-.245-.716-.618-1.355-1.172-1.886a4.486 4.486 0 00-1.418-.927 5.087 5.087 0 00-1.558-.353 5.66 5.66 0 00-1.414.062 4.268 4.268 0 00-2.06.96 5.07 5.07 0 00-.93 1.053l-.005.006-.015.02a.096.096 0 01-.008-.002l-.008-.003V13.948v-.302c0-.065.001-.131-.005-.196h-4.362V13.533l.01.482c.007.343.014.685.016 1.028.005 1.265.01 2.53.005 3.794-.005 1.656-.014 3.312-.022 4.968l-.012 2.483c0 .022 0 .044.002.067zm-6.802.045l.002-.025c.002-.032.004-.063.004-.095V13.45h4.37v12.846c0 .02 0 .04-.002.061l-.001.043H8.66zM8.413 9.187l-.013.059V9.6l.01.044c.009.032.017.064.021.096.025.208.071.412.16.603.342.738.93 1.162 1.736 1.296.41.069.823.054 1.231-.032.903-.19 1.668-.905 1.76-1.853.038-.384.01-.762-.13-1.124-.278-.716-.812-1.154-1.558-1.346a3.075 3.075 0 00-1.06-.069c-.312.03-.614.094-.894.233-.672.33-1.102.847-1.233 1.587-.009.051-.02.102-.03.152z"
              />
            </StyledSVG>
          </span>
        </a>
      ) : (
        <span>
          <svg
            width={size}
            height={size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 37 36"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18zm-2.537-9.645l.002.045c1.458 0 2.915 0 4.373-.002l-.002-.027c-.002-.023-.004-.045-.004-.068v-2.398-4.795a2.8 2.8 0 01.098-.819 2.56 2.56 0 01.535-.938c.176-.202.373-.381.608-.517.267-.155.557-.24.863-.27.31-.03.62-.017.924.056.52.124.908.42 1.168.88.175.307.271.64.331.986.061.352.08.708.08 1.064V26.4h4.357l.001-.022a.843.843 0 00.003-.06v-7.313c0-.428-.012-.856-.067-1.282a7.105 7.105 0 00-.313-1.402c-.245-.716-.618-1.355-1.172-1.886a4.486 4.486 0 00-1.418-.927 5.087 5.087 0 00-1.558-.353 5.66 5.66 0 00-1.414.062 4.268 4.268 0 00-2.06.96 5.07 5.07 0 00-.93 1.053l-.005.006-.015.02a.096.096 0 01-.008-.002l-.008-.003V13.948v-.302c0-.065.001-.131-.005-.196h-4.362V13.533l.01.482c.007.343.014.685.016 1.028.005 1.265.01 2.53.005 3.794-.005 1.656-.014 3.312-.022 4.968l-.012 2.483c0 .022 0 .044.002.067zm-6.802.045l.002-.025c.002-.032.004-.063.004-.095V13.45h4.37v12.846c0 .02 0 .04-.002.061l-.001.043H8.66zM8.413 9.187l-.013.059V9.6l.01.044c.009.032.017.064.021.096.025.208.071.412.16.603.342.738.93 1.162 1.736 1.296.41.069.823.054 1.231-.032.903-.19 1.668-.905 1.76-1.853.038-.384.01-.762-.13-1.124-.278-.716-.812-1.154-1.558-1.346a3.075 3.075 0 00-1.06-.069c-.312.03-.614.094-.894.233-.672.33-1.102.847-1.233 1.587-.009.051-.02.102-.03.152z"
              fill={color}
            />
          </svg>
        </span>
      )}
    </>
  );
};

const StyledSVG = styled.svg`
  ${(props: {color: string; hoverColor: string}) =>
    css`
      fill: ${props.color};
      transition: ease-in 0.2s;
      &:hover {
        fill: ${props.hoverColor};
      }
    `}
`;

export const Email = ({
  color = '#204568',
  hoverColor = 'black',
  link = '',
  size = '36',
}: SocialComponentsProps): JSX.Element => {
  return (
    <>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span>
            <StyledSVG
              hoverColor={hoverColor}
              color={color}
              width={size}
              height={size}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 39 39"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8 1A18.6 18.6 0 0 0 1 19.4c0 10.1 8.4 18.4 18.8 18.4s18.8-8.3 18.8-18.4C38.6 9.2 30.2 1 19.8 1zM9.4 11.4h20.8a.3.3 0 0 1 .2.6L21 17a2.4 2.4 0 0 1-2.3 0l-9.5-5a.3.3 0 0 1 .2-.6zm22.9 1.8a.8.8 0 0 1 .8.8v12a1.3 1.3 0 0 1-1.3 1.3h-24A1.3 1.3 0 0 1 6.5 26V14a.8.8 0 0 1 1.2-.7l11 6a2.4 2.4 0 0 0 2.3 0l11-6a.8.8 0 0 1 .3 0z"
              />
            </StyledSVG>
          </span>
        </a>
      ) : (
        <span>
          <svg
            width={size}
            height={size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 37 36"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.8 1A18.6 18.6 0 0 0 1 19.4c0 10.1 8.4 18.4 18.8 18.4s18.8-8.3 18.8-18.4C38.6 9.2 30.2 1 19.8 1zM9.4 11.4h20.8a.3.3 0 0 1 .2.6L21 17a2.4 2.4 0 0 1-2.3 0l-9.5-5a.3.3 0 0 1 .2-.6zm22.9 1.8a.8.8 0 0 1 .8.8v12a1.3 1.3 0 0 1-1.3 1.3h-24A1.3 1.3 0 0 1 6.5 26V14a.8.8 0 0 1 1.2-.7l11 6a2.4 2.4 0 0 0 2.3 0l11-6a.8.8 0 0 1 .3 0z"
              fill={color}
            />
          </svg>
        </span>
      )}
    </>
  );
};
