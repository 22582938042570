/**
 * Project-wide configuration.  Manage runtime values centrally, and
 * import them from this file.
 */

const prodUrl = 'https://koppers.netlify.app/';
const localUrl = 'http://localhost:3000/';
const prod = process.env.NODE_ENV === 'production';
const dev = !prod;

const deploy = {primeUrl: 'https://koppers.com'}; // require('./scripts/output/deploy.cfg');

const config = {
  sanity: {
    projectId:
      process.env.SANITY_PROJECT_ID ||
      process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ||
      'oqkrzhcj',
    // TODO: once the problem is fixed, change hardcode line with:
    // process.env.SANITY_STUDIO_API_PROJECT_ID,
    dataset:
      process.env.SANITY_DATASET ||
      process.env.SANITY_STUDIO_API_DATASET ||
      'production',
    token: process.env.SANITY_TOKEN || process.env.SANITY_STUDIO_TOKEN || null,
    apiVersion: '2021-10-21',
  },
  deploy,
  google: {
    key: 'AIzaSyDw7PT9skCV9W--wtDy3jbdU0NvwLyUwCs',
    // TODO: once the problem is fixed, change hardcode line with:
    // process.env.GOOGLE_SEARCH_KEY || process.env.NEXT_PUBLIC_GOOGLE_SEARCH_KEY,
    id: 'd688c4d282a1f48c0',
    // TODO: once the problem is fixed, change hardcode line with:
    // process.env.GOOGLE_SEARCH_ID || process.env.NEXT_PUBLIC_GOOGLE_SEARCH_ID,
  },
  project: {
    prodUrl,
  },
  prod,
  dev,
};

module.exports = config;
