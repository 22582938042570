import styled, {useTheme} from 'styled-components';
import {Theme, ThemeColorName} from '@design/theme';
import query from '@design/query';
import {SimpleComponent} from '@util/types';

export interface BlockComponentProps {
  narrow?: true;
  breadcrumbs?: true; //idk
  squish?: true;
  full?: true; //full bleed
  background?: ThemeColorName; //inputs color
  bleedRight?: boolean; //only exists in carousel TODO: @plat remove to carousel
}

// generate css calculate rule that uses padding to lock a virtual max width
const extraPaddingCalc = (marginPx: number, theme: Theme) =>
  `calc(${marginPx}px + ( max(0px, 100% - ${theme.breakpointMap.max}) / 2))`;

const Block = styled.div<BlockComponentProps>(
  ({narrow, squish, background, full, bleedRight}: BlockComponentProps) => {
    const theme = useTheme();

    // TODO: use styled components CSSObject Type
    const css: Record<string, any> = {
      boxSizing: 'border-box', // allows padding to size inner content
      width: '100%',
      // prevent margin collapse to preserve apparent padding when bg set
      '&>*': {overflow: 'hidden'},
    };

    // add background if specified
    if (background) {
      css.backgroundColor = theme.colors[background];
    }
    const padding = {
      mobile: {
        x: `${full ? 0 : theme.spacingPx.marginMobile}px`,
        y: `${full ? 0 : theme.spacingPx.marginMobile}px`,
      },
      tablet: {
        x: `${full ? 0 : theme.spacingPx.marginTablet}px`,
        y: `${
          full
            ? 0
            : squish
            ? theme.spacingPx.paddingMobile
            : theme.spacingPx.margin
        }px`,
      },
      desktop: {
        x: `${
          full
            ? 0
            : extraPaddingCalc(
                narrow ? theme.spacingPx.marginWide : theme.spacingPx.margin,
                theme,
              )
        }`,
        y: `${
          full
            ? 0
            : squish
            ? theme.spacingPx.paddingMobile
            : theme.spacingPx.margin
        }px`,
      },
    };

    css.padding = `${padding.mobile.y} ${padding.mobile.x}`;
    if (bleedRight) css.paddingRight = '0';

    css[`@media (${query.atLeast.tablet})`] = {
      padding: `${padding.tablet.y} ${padding.tablet.x}`,
      paddingRight: bleedRight ? 0 : null,
    };
    css[`@media (${query.atLeast.desktop})`] = {
      padding: `${padding.desktop.y} ${padding.desktop.x}`,
      paddingRight: bleedRight ? 0 : null,
    };

    return css;
  },
);

export const maxWidthPaddingTmplFn =
  (narrow?: true) =>
  ({theme}) => {
    const marginPx = narrow
      ? theme.spacingPx.marginWide
      : theme.spacingPx.margin;
    return `calc(${marginPx}px + ( max(0px, 100% - ${theme.breakpointMap.max}) / 2))`;
  };

export default Block as SimpleComponent<BlockComponentProps>;
