import {Block} from '@components/Layout';
import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';

import {color, font, query, space} from '@design/templateFns';
import {Button} from '../Button';
import {Close} from '../NavSvgComponent';

export interface AnnouncementBarProps {
  showAnnouncement: boolean;
  header: string;
  cta?: string;
  url?: string;
}

export const AnnouncementBar = ({
  showAnnouncement,
  header,
  cta,
  url,
}: AnnouncementBarProps): JSX.Element => {
  const [showAnnouncementBar, setShowAnnouncementBar] =
    useState(showAnnouncement);
  const onClick = () => {
    // Hide announcement bar
    setShowAnnouncementBar(false);

    // ...and set localStorage key with announcement bar value.
    // This will be used below.
    localStorage.setItem('announcementBarMessage', header);
  };

  useEffect(() => {
    // Check if "announcementBarMessage" cookie exists
    // and is the same message as when the announcement bar was closed (for 100 days).
    const prevMessage = localStorage.getItem('announcementBarMessage');

    // If it exists and the messages match, don't display announcement bar.
    prevMessage && prevMessage === header
      ? setShowAnnouncementBar(false)
      : setShowAnnouncementBar(true);
  }, []);

  return (
    <Block full>
      {showAnnouncement && (
        <StyledContainer showAnnouncementBar={showAnnouncementBar}>
          <StyledBox>
            <StyledParagraph>{header}</StyledParagraph>
            <StyledButtonContainer>
              <Button
                variant="primaryOrange"
                label={cta}
                url={url}
                arrow={true}
                arrowColor="black"
              />
            </StyledButtonContainer>
          </StyledBox>

          <StyledButton onClick={onClick}>
            <Close color="#192630" size="25px" />
          </StyledButton>
        </StyledContainer>
      )}
    </Block>
  );
};
const StyledContainer = styled.div`
  ${(props) =>
    props.showAnnouncementBar == true
      ? css`
          display: grid;
          grid-template-columns: 1fr 50px;
          background-color: ${color('accent')};
          /* align-items: center; */
          padding: 0 ${space('unit')};
        `
      : {display: 'none'}}
`;

const StyledBox = styled.div`
  color: ${color('accent')};
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;

  @media screen and (${query.below('tablet')}) {
    padding: ${space('marginMobile')} 0 0 ${space('unit')};
    flex-direction: column;
  }
`;

const StyledButtonContainer = styled.div``;

const StyledParagraph = styled.p`
  padding-bottom: 2px;
  ${font('headline-xxs')};
  color: ${color('primary')};
  display: inline-block;
  vertical-align: middle;
  margin: 0;
`;

const StyledButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  /* Styled Systems don't support these stylings */
  background: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
`;
