import {Navigation, NavigationProps} from '.';
import {SiteConfig} from '@data/types';
import {mapLink} from '@util/mapping/index';
// TODO: rearrange data into single var

const mapSiteConfig = (siteConfig: SiteConfig) => {
  if (!siteConfig) {
    return null;
  }
  const props: NavigationProps = {
    search: mapLink(siteConfig.searchLink),
    utilLinks: (siteConfig.utilityNavLinks.utilityNavItems || []).map(
      (link) => ({
        label: link.label,
        url: mapLink(link.url),
        _key: link._key,
      }),
    ),
    details: (siteConfig?.navConfig?.navLists || []).map((obj) => ({
      header: obj.details?.heading,
      body: obj.details?.body,
      image: obj.details?.image.asset.url || null,
    })),
    links: (siteConfig?.navConfig?.navLists || []).map((link) => ({
      label: link.label,
      url: mapLink(link.url),
      _key: link._key,
      links:
        link.links &&
        link.links.map((link) => ({
          label: link.label,
          url: mapLink(link.url),
          _key: link._key,
          links: link.links
            ? link.links.map((link) => ({
                label: link.label,
                url: mapLink(link.url),
                _key: link._key,
              }))
            : [],
        })),
    })),
  };
  return <Navigation {...props} />;
};

export default mapSiteConfig;
