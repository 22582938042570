import {ThemeFonts, ThemeFontWeights} from './theme';

export type FontDef = {
  themeFontFamily: ThemeFonts;
  themeFontWeight: ThemeFontWeights;
  fontSize: string;
  letterSpacing: String;
  lineHeight: string;
};

const defs = {
  'xxl-headline-regular': <FontDef>{
    themeFontFamily: 'headline',
    themeFontWeight: 'bold',
    fontSize: 'calc(100% + 4.285714285714286vw)',
    letterSpacing: '-0.01em',
    lineHeight: '110%',
  },
  'xl-headline-regular': <FontDef>{
    themeFontFamily: 'headline',
    themeFontWeight: 'bold',
    fontSize: 'calc(100% + 3.3333333333333335vw)',
    letterSpacing: '-0.01em',
    lineHeight: '110%',
  },
  'lrg-headline-regular': <FontDef>{
    themeFontFamily: 'headline',
    themeFontWeight: 'bold',
    fontSize: 'calc(100% + 2.738095238095238vw)',
    letterSpacing: '-0.01em',
    lineHeight: '115%',
  },
  'med-headline-regular': <FontDef>{
    themeFontFamily: 'headline',
    themeFontWeight: 'bold',
    fontSize: 'calc(100% + 2.142857142857143vw)',
    letterSpacing: '-0.01em',
    lineHeight: '110%',
  },
  'sm-headline-regular': <FontDef>{
    themeFontFamily: 'headline',
    themeFontWeight: 'bold',
    fontSize: 'calc(100% + 1.7857142857142858vw)',
    letterSpacing: '-0.01em',
    lineHeight: '110%',
  },
  'xs-headline-regular': <FontDef>{
    themeFontFamily: 'headline',
    themeFontWeight: 'bold',
    fontSize: 'calc(100% + 1.4285714285714286vw)',
    letterSpacing: '-0.01em',
    lineHeight: '112%',
  },
  'xxs-headline-regular': <FontDef>{
    themeFontFamily: 'headline',
    themeFontWeight: 'regular',
    fontSize: 'calc(100% + 1.1904761904761905vw)',
    letterSpacing: '-0.01em',
    lineHeight: '110%',
  },
  'lrg-body-regular': <FontDef>{
    themeFontFamily: 'body',
    themeFontWeight: 'regular',
    fontSize: '22px',
    letterSpacing: '0.015em',
    lineHeight: '134%',
  },
  'med-body-regular': <FontDef>{
    themeFontFamily: 'body',
    themeFontWeight: 'regular',
    fontSize: '20px',
    letterSpacing: '0.015em',
    lineHeight: '134%',
  },
  'sm-body-regular': <FontDef>{
    themeFontFamily: 'body',
    themeFontWeight: 'regular',
    fontSize: '18px',
    letterSpacing: '0.015em',
    lineHeight: '134%',
  },
  'xs-body-regular': <FontDef>{
    themeFontFamily: 'body',
    themeFontWeight: 'regular',
    fontSize: '16px',
    letterSpacing: '0.015em',
    lineHeight: '134%',
  },
  'xxs-body-regular': <FontDef>{
    themeFontFamily: 'body',
    themeFontWeight: 'regular',
    fontSize: '14px',
    letterSpacing: '0.015em',
    lineHeight: '134%',
  },
  'lrg-eyebrow-bold': <FontDef>{
    themeFontFamily: 'eyebrow',
    themeFontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '0.04em',
    lineHeight: '130%',
  },
  'med-eyebrow-bold': <FontDef>{
    themeFontFamily: 'eyebrow',
    themeFontWeight: 'bold',
    fontSize: '12px',
    letterSpacing: '0.12em',
    lineHeight: '130%',
  },
  'sm-eyebrow-bold': <FontDef>{
    themeFontFamily: 'eyebrow',
    themeFontWeight: 'bold',
    fontSize: '10px',
    letterSpacing: '0.12em',
    lineHeight: '138%',
  },
  'xl-subhead-bold': <FontDef>{
    themeFontFamily: 'subhead',
    themeFontWeight: 'bold',
    fontSize: '24px',
    letterSpacing: '0.015em',
    lineHeight: '124%',
  },
  'lrg-subhead-bold': <FontDef>{
    themeFontFamily: 'subhead',
    themeFontWeight: 'bold',
    fontSize: '22px',
    letterSpacing: '-0.01em',
    lineHeight: '112%',
  },
  'med-subhead-bold': <FontDef>{
    themeFontFamily: 'subhead',
    themeFontWeight: 'bold',
    fontSize: '18px',
    letterSpacing: '-0.01em',
    lineHeight: '112%',
  },
  'sm-subhead-bold': <FontDef>{
    themeFontFamily: 'subhead',
    themeFontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '-0.01em',
    lineHeight: '112%',
  },
  'btn-label': <FontDef>{
    themeFontFamily: 'btnLabel',
    themeFontWeight: 'bold',
    fontSize: '20px',
    letterSpacing: '-0.01em',
    lineHeight: '110%',
  },
  'btn-label-scale': <FontDef>{
    themeFontFamily: 'btnLabel',
    themeFontWeight: 'bold',
    fontSize: 'calc(100% + 0.352348vw)',
    letterSpacing: '-0.01em',
    lineHeight: '110%',
  },
};

export type FontDefName = keyof typeof defs;

export default defs;
