import {SiteConfig} from '@data/types';
import {KoppersFooterLogo} from '@components/KoppersFooterLogo';
import {FC} from 'react';
import {mapLink} from '@util/mapping/index';
import styled from 'styled-components';
import NextLink from 'next/link';

const Link = ({href, className, ...props}) => (
  <NextLink href={href}>
    {/* by forwarding class name we apply style to inner <a> tag instead */}
    <a {...{href, className, ...props}} />
  </NextLink>
);

// TODO: CRITICAL: remove styled-system calls
import {
  space,
  typography,
  flexbox,
  layout,
  grid,
  size,
  border,
} from 'styled-system';

import {color, font, query} from '@design/templateFns';
import {Address} from './address';
import {Facebook, LinkedIn} from '../SocialComponents';

interface FooterProps {
  siteConfig: SiteConfig;
}

export const Footer: FC<FooterProps> = ({siteConfig}) => {
  if (!siteConfig?.footerConfig) {
    return null;
  }

  const {
    footerConfig,
    bottomFooterConfig,
    AddressLine1,
    AddressLine2,
    phone1,
    phone2,
    email,
  } = siteConfig;

  const columnArr = [
    footerConfig.col1,
    footerConfig.col2,
    footerConfig.col3,
    footerConfig.col4,
  ];

  const bottomLinkArr = bottomFooterConfig.bottomCol1;

  // basically an _array_ of footer links
  type footerCol = SiteConfig['footerConfig']['col1'];

  // Created ColumnData Function to Prevent
  // Styled Systems Styling Repetition
  const columnData = (colArr: footerCol[]) => {
    return colArr.map((col, colIndex) => (
      <div key={colIndex}>
        {col.map((link, linkIndex) =>
          linkIndex === 0 ? (
            <StyleHeaderdLink
              display="block"
              fontWeight="bold"
              paddingBottom={['17px', '17px', null, '26px']}
              href={mapLink(link.url)}
              key={link._key}
            >
              {link.title}
            </StyleHeaderdLink>
          ) : (
            <StyledLink
              display="block"
              fontWeight="regular"
              paddingBottom={['15px', '15px', null, '15px']}
              key={link._key}
              href={mapLink(link.url)}
            >
              {link.title}
            </StyledLink>
          ),
        )}
      </div>
    ));
  };

  return (
    <FooterFrame>
      <StyledBox
        fontFamily="headline"
        bg="sky"
        display={['block', null, null, 'grid']}
        gridTemplateRows={['1fr 165px', '1fr 90px', null, null]}
        p={['34px 19px 0', '50px 50px 0', null, '76px 76px 20px']}
      >
        <StyledBox
          display={['none', null, null, 'grid']}
          gridTemplateColumns="25% 12% 12% 11% 14% 12%"
          gridGap="38px"
          pb="140px"
          borderBottom="rgba(31, 81, 63, 0.7) solid 1px"
        >
          {/* Pulling from Address Component I Created Located in Same File Folder */}
          <Address
            AddressLine1={AddressLine1}
            AddressLine2={AddressLine2}
            email={email}
            phone={phone1}
            fax={phone2}
          />
          {/* columnData iterates and renders Columns 1 - 4 */}
          {columnData(columnArr)}

          <div>
            {/* This is UNIQUE column 5 (has all fully bolded list compared to other columns)  */}
            {footerConfig.col5.map((x) => (
              <StyledLink
                display="block"
                fontWeight="bold"
                paddingBottom={['10px', '15px', '15px', '15px']}
                href={mapLink(x.url)}
                key={x._key}
                target={x.title == 'Investor Relations' ? '_blank' : ''}
              >
                {x.title}
              </StyledLink>
            ))}
          </div>
        </StyledBox>
        <StyledBox
          display={['grid', null, null, 'none']}
          gridTemplateColumns={['100%', '50% 50%', null, 'none']}
          borderBottom="rgba(31, 81, 63, 0.7) solid 1px"
        >
          {/* Pulling from Address Component I Created Located in Same File Folder */}
          <Address
            AddressLine1={AddressLine1}
            AddressLine2={AddressLine2}
            email={email}
            phone={phone1}
            fax={phone2}
          />
          <StyledBox
            display={['grid', null, null, 'none']}
            gridTemplateColumns="60% 40%;"
            p={['20px 0', '0 0 15px', null]}
          >
            <div>
              {/* columnData iterates and renders Every First Item from Columns 1 - 4 */}
              {columnArr.map((col) => (
                <StyledLink
                  display="block"
                  fontWeight="bold"
                  paddingBottom={['10px', '17px', '17px', '17px']}
                  m="0"
                  href={mapLink(col[0].url)}
                  target={col[0].title == 'Investor Relations' ? '_blank' : ''}
                  key={col[0]._key}
                >
                  {col[0].title}
                </StyledLink>
              ))}
            </div>
            <div>
              {footerConfig.col5.map((x) => (
                <StyledLink
                  display="block"
                  fontWeight="bold"
                  paddingBottom={['14px', '17px', '17px', '17px']}
                  m="0"
                  href={mapLink(x.url)}
                  key={x._key}
                  target={x.title == 'Investor Relations' ? '_blank' : ''}
                >
                  {x.title}
                </StyledLink>
              ))}
            </div>
          </StyledBox>
        </StyledBox>
        <StyledBox
          display={['flex', null, null, 'grid']}
          gridTemplateColumns={['none', null, null, '15% auto 22%']}
          alignItems={['flex-start', null, null, 'center']}
          flexDirection={['column', null, null, 'none']}
          p={['20px 0', null, null, 'none']}
        >
          <StyledBox
            display="flex"
            alignItems="center"
            gap="15px"
            justifySelf={['inherit', 'self-start', null, null]}
            p={['20px 0', 'initial', null, null]}
          >
            <StyledHeading
              fontWeight="bold"
              fontSize={0}
              m="0"
              lineHeight="24px"
              letterSpacing="-0.015em"
              p={['0 10px 0 0', null, null, '0 20px 0 0']}
            >
              Follow us:
            </StyledHeading>
            <SocialContainer>
              <Facebook
                color="#FFF"
                hoverColor="#01617a"
                link="https://www.facebook.com/koppersinc"
                size="25"
              />
              <LinkedIn
                color="#FFF"
                hoverColor="#01617a"
                link="https://www.linkedin.com/company/koppers-inc./"
                size="25"
              />
            </SocialContainer>
          </StyledBox>
          <StyledPrivacyBox
            display="flex"
            justifyContent={['flex-start', 'center', null, null]}
            flexDirection={['column', null, 'row', 'row']}
            alignItems="flex-start"
            p={['0', 'initial', null, null]}
            gap="10px"
          >
            {bottomLinkArr.map((obj, index) => (
              <StyledContainerSpan key={obj._key}>
                <StyledBottomLink
                  display="block"
                  fontSize={[0, 1, null, null]}
                  fontWeight="bold"
                  lineHeight={['22px', '24px', null, '30px']}
                  letterSpacing="-0.015em"
                  paddingBottom={['10px', '25px', null, 'initial']}
                  m="0"
                  target="_blank"
                  href={mapLink(obj.url)}
                >
                  {obj.title}
                </StyledBottomLink>
                <StyledSpan>
                  {index !== bottomLinkArr.length - 1 && (
                    <StyledSpan>|</StyledSpan>
                  )}
                </StyledSpan>
              </StyledContainerSpan>
            ))}
          </StyledPrivacyBox>
          <StyledBox
            display="flex"
            flexDirection={['column', 'row', null, null]}
            alignItems={['flex-start', 'center', null, null]}
            gap="15px"
            justifySelf={['inherit', 'self-end', null, null]}
            p={['20px 0', '10px 0px', null, null]}
          >
            <StyledHeading
              fontWeight="regular"
              fontSize={0}
              m="0"
              lineHeight="24px"
              paddingRight={['10px', '25px', null, 'initial']}
              letterSpacing="-0.015em"
            >
              © {new Date().getFullYear() + ' ' + 'Koppers Inc.'}
            </StyledHeading>
            <FooterLogoFrame>
              <KoppersFooterLogo />
            </FooterLogoFrame>
          </StyledBox>
        </StyledBox>
      </StyledBox>
    </FooterFrame>
  );
};

const StyledContainerSpan = styled.div`
  display: flex;
  justify-content: center;

  > span {
    display: block;

    @media screen and (${query.below('desktop')}) {
      display: none;
    }
  }
`;
const StyledSpan = styled.span`
  white-space: nowrap;
  margin: 0 10px;
`;

const FooterFrame = styled.div`
  background-color: ${color('primary')};
  width: 100%;
`;
const FooterLogoFrame = styled.div`
  padding-left: 36px;

  @media screen and (${query.below('tablet')}) {
    padding-left: 0;
  }
`;

const StyledBox = styled.div`
  background-color: ${color('primary')};
  color: ${color('bg')};
  ${typography}
  ${flexbox}
  ${space}
  ${layout}
  ${grid}
  ${size}
  ${border}
`;

const StyledPrivacyBox = styled(StyledBox)`
  @media screen and (${query.below('tablet')}) {
    gap: 10px;
  }
`;
const StyleHeaderdLink = styled(Link)`
  ${font('headline-xxxs')};
  font-weight: 700;
  color: ${color('bg')};
  font-size: 15px;
  ${layout}
  ${space}
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #b1b1b1;
  }
`;

const StyledLink = styled(Link)`
  ${font('body-sm-regular')};
  ${typography}
  ${space}
  color: ${color('bg')};
  ${layout}
  //Styled System Doesn't Support These Stylings
  cursor: pointer;
  text-decoration: none;
  //--------------------
  &:hover {
    color: #b1b1b1;
  }
`;

const StyledBottomLink = styled(Link)`
  ${font('body-sm-regular')};
  color: ${color('bg')};
  text-decoration: none;
  white-space: nowrap;
  font-size: 13px;
  &:hover {
    color: #b1b1b1;
  }
  @media screen and (${query.below('desktop')}) {
    margin: 0;
  }
`;

const StyledHeading = styled.h2`
  ${font('body-sm-regular')};
  ${typography}
  ${space}
  color: ${color('bg')};
  white-space: nowrap;
`;

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  a {
    padding: 5px;
    @media (max-width: 900px) {
      svg {
        max-width: 30px;
      }
      padding: 3px;
    }
  }
`;
