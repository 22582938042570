import {useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import type {BasicText, ResolvedImageData} from '@data/types';
import Link from '@components/Link';
import {Search, NavArrow} from '../MiscComponents/index';
import {Logo} from '../Logo/index';
import {color, space, query, font} from '@design/templateFns';
import {NavigationProps} from './index';

import {useRouter} from 'next/router';

interface LinkObject {
  label: string;
  url: string;
  _key: string;
  links?: LinkObject[];
}
interface DetailObject {
  header?: string;
  body?: string;
  image?: string;
}

interface UtilLink {
  label: string;
  url: string;
  _key: string;
}

/****
 * DESKTOP
 * */
const StyledDesktopMenuWrapper = styled.div`
  display: none;
  @media (${query.atLeast('desktop')}) {
    display: block;
    padding: 0 ${space('subNav')};
  }
`;

const StyledTopSection = styled.div`
  padding: ${space('marginMobile')} 0;
  border-bottom: 1px solid #eeeeef;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTopRightSection = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  line-height: 1px;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledTopRightSectionItem = styled.li`
  ${font('body-sm-regular')} // #font-check
  margin-right: ${space('marginMobile')};
  display: flex;
  align-items: center;
  color: ${color('fg')};
  &:last-child {
    margin-right: 0;
  }

  span {
    margin-right: ${space('marginMobile')};
  }
`;

const StyledBottomSection = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const StyledSection = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
`;

const StyledLink = styled.li`
  margin-right: ${space('marginNav')};
  padding: ${space('subNav')} ${space('margin')};
  padding: ${space('unitNav')} 0 ${space('marginMobile')};
  text-transform: uppercase;
  border-bottom: 4px solid transparent;
  position: relative;

  .StyledSubLinkWrapper {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    padding: ${space('margin')} ${space('margin')};
    display: none;
    z-index: 2;
    min-width: 500px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  a {
    color: ${color('primary')};
    ${font('headline-xxxs')}
    text-decoration: none;
  }

  &:hover {
    border-color: ${color('cta')};
    a {
      color: ${color('fg')};

      &:hover {
        color: ${color('cta')};
      }
    }
    .StyledSubLinkWrapper {
      display: block;

      body.body-no-hover & {
        display: none;
      }
    }
  }
`;

const SubNav = styled.div`
  display: flex;
  gap: 100px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledSubLink = styled.li`
  ${({header}) => header && `font-weight: 700;`}
  margin-bottom: ${space('subNavMobile')};
  text-transform: none;

  a {
    ${font('headline-xxs')};
    white-space: nowrap;
  }

  ul {
    list-style-type: none;
    padding-left: ${space('margin')};
    margin-bottom: ${space('marginMobile')};

    li {
      font-weight: normal;
    }
  }
`;

const StyledSubLinkWrapper = styled.ul`
  list-style-type: none;
`;

const bounceAnimation = keyframes`
  0%   {left: 20px;}
  50%  {left: 30px;}
  100% {left: 20px;}
`;

const BackgroundSection = styled.span`
  position: relative;

  &:hover {
    path {
      /* stroke: white;
      transition: ease-in 0.1s; */
    }
  }
`;

const BackgroundOverlay = styled.div`
  background: linear-gradient(
    12.59deg,
    rgba(0, 0, 0, 0.6) 21.28%,
    rgba(0, 0, 0, 0) 74.76%
  );
  border-radius: 5px;
  height: 100%;

  &:hover {
    background: linear-gradient(
      12.59deg,
      rgba(0, 0, 0, 0.8) 21.28%,
      rgba(0, 0, 0, 0) 74.76%
    );
    transition: ease-in-out 0.8s;
  }

  &:hover {
    > div {
      animation: ${bounceAnimation} 1s normal;
    }
  }
`;

const CtaContainer = styled.div`
  position: absolute;
  width: 18%;
  height: 100%;
  right: 0;
  background: #ffbb38;
  opacity: 0.9;
`;

const BackgroundImage = styled.div`
  background-image: url(${({image}) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 350px;
  width: 350px;
  border-radius: 5px;
  overflow: hidden;

  &:hover {
    span {
      animation: ${bounceAnimation} 1s normal;
    }
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  color: white;
  bottom: 10px;
  left: 20px;
  max-width: 250px;
`;

const BackgroundHeader = styled.p`
  ${font('body-sm-regular')}
  font-weight: 500;
  text-transform: none;
`;

const BackgroundBody = styled.p`
  ${font('body-sm-regular')} // #font-check xs->sm
  text-transform: none;
  line-height: 160%;
`;

const ArrowSVGContainer = styled.span`
  display: block;
  position: absolute;
  bottom: 15px;
  left: 30%;
`;

const UtilHover = styled.div`
  &:hover {
    color: ${color('cta')};
    font-family: azo-sans-web;
  }
`;

export const DesktopMenu = ({
  links,
  details,
  utilLinks,
  search,
}: NavigationProps): JSX.Element => {
  // To prevent hover states from persisting
  // between route/page changes, we need to
  // implement a quick removal/adding of hover styles
  // when this event occurs.

  const router = useRouter();

  useEffect(() => {
    // Adds no-hover class to body
    const addBodyClass = () => {
      document.body.classList.add('body-no-hover');
    };

    // ...and removes it.
    const removeBodyClass = () => {
      setTimeout(() => {
        document.body.classList.remove('body-no-hover');
      }, 750);
    };

    router.events.on('routeChangeStart', addBodyClass);
    router.events.on('routeChangeComplete', removeBodyClass);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', addBodyClass);
      router.events.off('routeChangeComplete', removeBodyClass);
    };
  }, []);

  // Rendering different link levels
  const renderTopLinks = (links: LinkObject[], details: DetailObject[]) => {
    return links.map((link, index) => {
      const obj = details[index];
      return (
        <StyledLink key={link._key} fontSize={0}>
          <Link href={link.url}>{link.label}</Link>

          {link.links && (
            <StyledSubLinkWrapper className="StyledSubLinkWrapper">
              <SubNav>
                <div>{renderSubLinks(link.links)}</div>
                {obj.image && (
                  <BackgroundSection href={link.url}>
                    <BackgroundImage image={obj.image}>
                      <CtaContainer>
                        <ArrowSVGContainer>
                          <NavArrow color="black" size="25" />
                        </ArrowSVGContainer>
                      </CtaContainer>
                      <BackgroundOverlay>
                        <BackgroundContainer>
                          <BackgroundHeader>{obj.header}</BackgroundHeader>
                          <BackgroundBody>{obj.body}</BackgroundBody>
                        </BackgroundContainer>
                      </BackgroundOverlay>
                    </BackgroundImage>
                  </BackgroundSection>
                )}
              </SubNav>
            </StyledSubLinkWrapper>
          )}
        </StyledLink>
      );
    });
  };

  const renderSubLinks = (links: LinkObject[]) => {
    return links.map((link) => {
      return (
        <StyledSection key={link._key}>
          <StyledSubLink header={true} fontSize={1}>
            <Link href={link.url}>{link.label}</Link>
            {link.links && (
              <ul>
                <li>{renderSubLinks(link.links)}</li>
              </ul>
            )}
          </StyledSubLink>
        </StyledSection>
      );
    });
  };

  const renderUtilLinks = (utilLinks: UtilLink[]) => {
    return utilLinks.map((link) => {
      return (
        <StyledTopRightSectionItem key={link._key}>
          <UtilHover>
            <Link href={link.url}>{link.label}</Link>
          </UtilHover>
        </StyledTopRightSectionItem>
      );
    });
  };

  return (
    <StyledDesktopMenuWrapper fontFamily="body">
      <StyledTopSection>
        <Link href="/">
          <Logo />
        </Link>
        <StyledTopRightSection fontSize={0}>
          <StyledTopRightSectionItem>
            <Search
              color="#1B76B0"
              hoverColor="#1B76B0"
              link={search}
              size="15"
            />
          </StyledTopRightSectionItem>
          <>{renderUtilLinks(utilLinks)}</>
        </StyledTopRightSection>
      </StyledTopSection>
      <StyledBottomSection>
        {renderTopLinks(links as LinkObject[], details)}
      </StyledBottomSection>
    </StyledDesktopMenuWrapper>
  );
};
