import MappedComponentContext from '@components/BlockMapper/MappedComponentContext';
import NextLink, {LinkProps as NextLinkProps} from 'next/link';
import {useContext} from 'react';
import styled from 'styled-components';
import type {Theme, ThemeColorName} from '@design/theme';
import log from '@util/logging';

interface LinkProps extends NextLinkProps {
  children?: any;
  className?: string;
}

const Wrapper_ = styled.span`
  text-decoration: none;
  a {
    text-decoration: none;
  }
`;

const Link = ({href, children, className, ...props}: LinkProps) => {
  const blockContext = useContext(MappedComponentContext);

  if (!href) {
    return (
      <span
        {...{
          className: `__hzkb_failed_link${className ? ', ' + className : ''}`,
          'data-link-failure': 'empty href',
          children,
        }}
      />
    );
  }

  if (Array.isArray(children) && children.length > 1) {
    log.error('TOO MANY KIDS', {props, blockContext});
    return (
      <span
        {...{
          className: `__hzkb_failed_link${className ? ', ' + className : ''}`,
          'data-link-failure': 'multi-child',
          children,
        }}
      />
    );
  }

  // on-page anchors
  if (`${href}`.startsWith('#')) {
    return (
      <Wrapper_>
        <a
          {...{
            'data-link-type': 'anchor',
            href: `${href}`,
            children,
            className,
          }}
        />
      </Wrapper_>
    );
  }

  // docs seperates
  if (`${href}`.startsWith('/doc/')) {
    return (
      <Wrapper_>
        <a
          {...{
            'data-link-type': 'doc',
            target: '_blank',
            href: `${href}`,
            children,
            className,
          }}
        />
      </Wrapper_>
    );
  }

  // off-site links ( anything not starting with '/' )
  if (!`${href}`.startsWith('/')) {
    return (
      <Wrapper_>
        <a
          {...{
            'data-link-type': 'maybe-external',
            target: '_blank',
            href: `${href}`,
            children,
            className,
          }}
        />
      </Wrapper_>
    );
  }

  // onsite links with mitigation for weird next link issues
  const legacyBehavior = !!(children?.type == 'a');
  return (
    <Wrapper_>
      <NextLink
        data-link-type="internal"
        {...{href, children, legacyBehavior, className, ...props}}
      />
    </Wrapper_>
  );
};

export default Link;
