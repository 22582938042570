import {color} from '@design/templateFns';

export const primary = {
  backgroundColor: color('bg'),
  color: color('primary'),
  border: `1px solid`,
  borderRadius: '41px',
  borderColor: color('bg'),
  textDecoration: 'none',
  cursor: 'pointer',
  transition: `color 0.3s ease-in-out,
          background-color 0.3s ease-in-out,
          border-color 0.3s ease-in-out`,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ':not(:disabled):hover': {
    bg: '#e3e3e3',
    borderColor: '#e3e3e3',
  },

  ':disabled': {
    border: 'none',
    cursor: 'default',
    bg: 'backgroundDisabled',
  },
  padding: '13.5px 20px',
  whiteSpace: 'nowrap',

  '@media only screen and (max-width: 600px)': {
    padding: '13px 25px',
  },
};

export const primaryOrange = {
  ...primary,
  backgroundColor: color('accent'),
  color: color('primary'),
  borderColor: color('accent'),

  ':not(:disabled):hover': {
    bg: '#e9ac36',
    borderColor: '#e9ac36',
  },
};

export const primaryBlue = {
  ...primary,
  backgroundColor: color('cta'),
  color: color('bg'),
  borderColor: color('cta'),

  ':not(:disabled):hover': {
    bg: '#034b5f',
    borderColor: '#034b5f',
  },
};

export const primaryGreen = {
  ...primary,
  backgroundColor: color('secondaryBg'),
  color: color('bg'),
  borderColor: color('secondaryBg'),

  ':not(:disabled):hover': {
    bg: '#173b2e',
    borderColor: '#173b2e',
  },
};

export const secondary = {
  color: color('bg'),
  textDecoration: 'none',
  cursor: 'pointer',
  transition: `color 0.3s ease-in-out,
          background-color 0.3s ease-in-out,
          border-color 0.3s ease-in-out`,

  ':not(:disabled):hover': {
    /* Add Hover Arrow Effect */
  },
};

export const secondaryBlack = {
  ...secondary,
  color: color('primary'),
};

export const secondaryBlue = {
  ...secondary,
  color: color('cta'),
};
