import React from 'react';

import styled, {css} from 'styled-components';

interface LogoProps {
  color?: string;
  size?: number;
  hoverColor?: string;
}

export const Logo = ({
  color = '#000',
  hoverColor = '#000',
  size = 150,
}: LogoProps) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 136 49"
      x="0px"
      y="0px"
      width={size}
    >
      {/* <svg width="136" height="49" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <g clipPath="url(#a)">
        <path
          d="M91.974 40.491h-.398a25.09 25.09 0 0 0 18.739 8.343c3.642 0 7.152-.794 10.264-2.185h-13.177c-6.026 0-11.456-2.317-15.428-6.158ZM107.402 2.35h13.177A25.234 25.234 0 0 0 110.315.166 25.09 25.09 0 0 0 91.576 8.51h.398a22.35 22.35 0 0 1 15.428-6.158ZM121.771 26.652l13.111 1.192c.133-.795.199-1.656.199-2.45l-13.31.993v.265ZM121.24 30.492l12.78 1.192c.265-.728.463-1.523.596-2.317l-13.31.86c0 .067-.066.133-.066.265ZM132.498 35.392c.397-.728.728-1.523 1.06-2.317l-13.244.927c0 .066-.066.132-.066.265l12.25 1.125ZM130.312 38.902c.53-.729 1.06-1.457 1.523-2.252l-13.111.928c-.066.066-.066.132-.132.264l11.72 1.06ZM116.143 41.22l11.124.993c.728-.729 1.457-1.457 2.119-2.252l-13.045.994c-.066.132-.132.198-.198.264ZM122.964 45.457c1.125-.662 2.185-1.39 3.178-2.251l-13.442 1.06c-.132.066-.265.198-.397.264l10.661.927ZM135.081 23.672c0-.86-.066-1.655-.199-2.45l-13.111 1.192v.265l13.31.993ZM134.616 19.7a13.251 13.251 0 0 0-.596-2.318l-12.78 1.191c0 .067 0 .133.066.265l13.31.861ZM133.557 15.991c-.331-.795-.662-1.59-1.059-2.317l-12.316 1.191c0 .067.066.133.066.265l13.309.861ZM131.835 12.415c-.463-.794-.993-1.523-1.523-2.25l-11.72 1.125c.066.066.066.132.132.265l13.111.86ZM129.386 9.038c-.662-.794-1.391-1.523-2.119-2.251l-11.124.993c.066.066.132.199.198.265l13.045.993ZM126.142 5.794c-.993-.795-2.053-1.59-3.178-2.185l-10.661.86c.132.067.265.2.397.265l13.442 1.06Z"
          fill="#59AC40"
        />
        <path
          d="M20.792 10.098H17.15S4.569 22.017 3.708 22.878v-12.78H0v29.466h3.708V25.99c.86.927 13.11 13.508 13.11 13.508h4.835S7.416 24.732 7.019 24.334c.397-.33 14.899-14.302 14.899-14.302H20.792v.066Z"
          fill="#01617A"
        />
        <path
          d="M19.467 24.798c0-2.185 1.06-4.437 2.847-5.893 1.325-1.126 2.914-1.656 4.636-1.656 4.171 0 7.614 3.377 7.614 7.549 0 4.436-3.244 7.88-7.482 7.88-4.304-.067-7.615-3.444-7.615-7.88Zm0-7.681c-2.119 2.052-3.31 4.767-3.31 7.68 0 6.225 4.634 10.927 10.793 10.927 3.244 0 5.959-1.192 8.078-3.51 1.92-2.053 2.847-4.436 2.847-7.416 0-2.914-1.126-5.562-3.244-7.681-2.12-2.053-4.636-3.046-7.615-3.046-2.98 0-5.496.993-7.549 3.046ZM46.219 17.646c3.046 0 4.238.861 4.238 3.179 0 2.185-1.192 3.046-4.105 3.046h-2.914v-6.224h2.781Zm-5.628-3.178h-.398v20.924h3.245v-8.277h2.649c2.45 0 3.906-.33 5.098-1.125 1.722-1.126 2.715-3.112 2.715-5.297 0-2.318-1.06-4.238-3.046-5.364-1.258-.728-2.98-.86-5.099-.86h-5.164ZM61.78 17.646c3.046 0 4.238.861 4.238 3.179 0 2.185-1.192 3.046-4.106 3.046H59v-6.224h2.78Zm-5.562-3.178h-.398v20.924h3.245v-8.277h2.649c2.45 0 3.906-.33 5.098-1.125 1.722-1.126 2.715-3.112 2.715-5.297 0-2.318-1.06-4.238-3.046-5.364-1.258-.728-2.98-.86-5.098-.86h-5.165ZM82.704 14.468H71.248v20.858h11.654v-3.178h-8.41v-5.695h8.41v-3.178h-8.41v-5.628h8.41v-3.179h-.198ZM86.28 14.468h-.397v20.924h3.244V17.646h2.252c1.788 0 3.046 0 3.973.795.794.728 1.192 1.722 1.192 2.914 0 1.92-1.523 3.244-3.642 3.244h-3.046l6.754 10.727h3.84s-4.9-7.747-5.165-8.21c2.782-.663 4.636-2.98 4.636-5.828 0-2.251-1.126-4.436-2.848-5.628-1.523-1.06-3.112-1.258-5.164-1.258l-5.629.066ZM101.377 19.898c0 3.112 2.913 4.701 5.43 6.026 1.92 1.059 3.774 1.986 3.774 3.443 0 2.119-1.457 3.377-3.841 3.377-1.589 0-2.913-.331-4.105-1.06l-.861-.53-.331 3.51.464.199c.993.397 2.582.927 4.767.927 3.576 0 7.218-1.92 7.218-6.158 0-3.51-2.98-5.099-5.562-6.423-1.921-.994-3.576-1.854-3.576-3.311 0-2.516 2.847-2.715 3.708-2.715 1.324 0 2.583.265 3.311.662l.794.397.596-3.178-.529-.199c-1.722-.662-3.642-.662-4.172-.662-4.238-.066-7.085 2.318-7.085 5.695Z"
          fill="#01617A"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h135.743v49H0z" />
        </clipPath>
      </defs>
    </StyledSVG>
  );
};

const StyledSVG = styled.svg`
  ${(props: {color: string; hoverColor: string}) =>
    css`
      path {
        stroke: ${props.color};
        fill: ${props.color};
        transition: ease-in 0.2s;
      }
      &:hover {
        path {
          stroke: ${props.hoverColor};
          fill: ${props.hoverColor};
        }
      }
    `}
`;
