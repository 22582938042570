import styled from 'styled-components';
import {RightArrowNoLine} from '../Arrow/index';
import {typography} from 'styled-system'; // TODO: CRITICAL: remove styled-system calls
import {fontWeight, space, fontFamily} from '../../themes/fn';
import {font} from '@design/templateFns';
export interface BackButtonProps {
  menuLevel: number;
  parent: string;
  handleChangeLevel: (number) => void;
}

const Wrapper = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 1px;
  width: 100%; // Ensures text doesn't overlap/blow up on Safari.
  ${fontFamily('body')};
  ${fontWeight('bold')};
`;

const ArrowWrapper = styled.div`
  transform: rotate(180deg);
  margin-right: ${space('md')};
`;

const Title = styled.div`
  display: block;
  width: 100%;
  z-index: 1;
  padding: 18px 0;
  ${font('eyebrow-sm')};

  &.z-index-up {
    z-index: 2;
  }
`;

export const BackButton = ({
  menuLevel,
  parent,
  handleChangeLevel,
}: BackButtonProps): JSX.Element => {
  const handleClickBack = () => {
    handleChangeLevel(-1);
  };
  return (
    <Wrapper onClick={handleClickBack}>
      <ArrowWrapper>
        <RightArrowNoLine />
      </ArrowWrapper>
      {menuLevel === 2 ? (
        <Title>Main Menu</Title>
      ) : (
        <Title className="z-index-up">{parent}</Title>
      )}
    </Wrapper>
  );
};
