import brand, {BrandTheme, BrandPallete} from './brand';
import type {ThemeBasePalette} from './types';

export type ThemePalette = ThemeBasePalette & BrandPallete;

// TODO: reduce number of spacing toekns here, additional spacing
// can just be custom outside a small set of helpfu defaults
const spacingPx = {
  unit: 8,
  unitNav: 10,
  marginMobilNav: 15,
  marginMobile: 20,
  margin: 80,
  marginTablet: 64,
  marginWide: 200,
  padding: 60,
  paddingVertical: 40,
  paddingMobile: 30,
  marginNav: 30,
  subNav: 75,
  subNavMobile: 5,
};

export type ThemeSpacingName = keyof typeof spacingPx;

const spacing = Object.fromEntries(
  Object.entries(spacingPx).map(([key, px]) => [key, `${px}px`]),
) as {[key in ThemeSpacingName]: string};

// for now the brand fonts _are_ the semantic platform fonts, so mapping is
// just an assignment
const fonts = brand.fonts;

// default palette
const colors: ThemePalette = {
  // spread brand.colors first, to prevent overwriting below
  ...brand.colors,
  bg: brand.colors.white,
  fg: brand.colors.charcoal,
  panel: brand.colors.ivory,
  accent: brand.colors.marigold,
  cta: brand.colors.blue,
  primary: brand.colors.midnight,
  shadow: brand.colors.shadow,
  secondaryBg: brand.colors.forest,
  black: brand.colors.black,
  transparent: brand.colors.transparent,
  link: brand.colors.blue,
};

const breakpoints = ['768px', '1024px', '1440px'];
const breakpointMap = {
  tablet: breakpoints[0],
  desktop: breakpoints[1],
  max: breakpoints[2],
};

const theme = {
  brand,
  breakpointMap,
  breakpoints,
  colors,
  fonts,
  spacing,
  spacingPx,
};

export type ThemeColorName = keyof ThemePalette;
export type ThemeFontName = keyof typeof fonts;
export type ThemeBreakpointName = keyof typeof breakpointMap;
export type Theme = typeof theme;

export type ThemePatch = Partial<Theme>;

export default theme;
