export type ThemeColorName =
  | 'text'
  | 'background'
  | 'primary'
  | 'secondary'
  | 'greyDark'
  | 'lightGrey'
  | 'grey92'
  | 'grey50'
  | 'grey10'
  | 'grey2'
  | 'alert'
  | 'navy'
  | 'blue'
  | 'charcoal'
  | 'yellow'
  | 'cream'
  | 'green'
  | 'sky'
  | 'orange'
  | 'black'
  | 'midnight'
  | 'lightSky'
  | 'boxShadow'
  | 'line'
  | 'red'
  | 'hoverRed'
  | 'pressedRed'
  | 'pressedGrey'
  | 'errorRed'
  | 'successGreen'
  | 'disabledGrey';

export type ThemeColorList = {
  [index in ThemeColorName]: string;
};

const colors: ThemeColorList = {
  text: '#111',
  background: '#fff',
  primary: '#000000',
  secondary: '#FFFFFF',
  greyDark: '#434343',
  grey92: '#3B3B3C',
  grey50: '#939598',
  grey10: '#E6E7E8',
  grey2: '#F8F8F8',
  lightGrey: '#D3D3D3',
  alert: '#6F1014',
  navy: '#204568',
  blue: '#1B76B0',
  yellow: '#FFBB38',
  charcoal: '#282828',
  cream: '#F7F3E8',
  green: '#1F513F',
  sky: '#E7EDF5',
  orange: '#D15D34',
  black: '#000000',
  midnight: '#192630',
  lightSky: '#E1E9ED',
  boxShadow: '#00000033',
  line: 'rgba(0, 0, 0, 0.1)',
  red: '#D8262E',
  hoverRed: '#9E252A',
  pressedRed: '#6F1014',
  pressedGrey: '#3B3B3C',
  errorRed: '#D8262E',
  successGreen: '#026F0D',
  disabledGrey: '#E6E7E8',
};

export default colors;
