import React from 'react';
import styled, {useTheme} from 'styled-components';
import {SimpleComponent} from '@util/types';
import {Theme} from '@design/theme';

export interface LayoutProps {
  preview?: boolean;
}

export const Container = styled.div<LayoutProps>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  overflow: auto;
  ${({preview}) => {
    if (preview) {
      return {border: 'dashed orange 5px'};
    }
  }}
`;

const Layout: SimpleComponent<LayoutProps> = (props) => {
  return <Container {...props} />;
};
export default Layout;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;

  @media (min-width: ${(theme) => theme.breakpointMap.tablet}) {
    grid-template-columns: repeat(3, 3fr);
  }

  @media (min-width: ${(theme) => theme.breakpointMap.desktop}) {
    grid-template-columns: repeat(4, 4fr);
  }
`;

export const Hr = styled.hr`
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

export const Spacer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-flex;
`;
