import styled from 'styled-components';
import {MobileMenu} from './MobileMenu';
import {DesktopMenu} from './DesktopMenu';
import {query} from '@design/templateFns';
import type {BasicText, ResolvedImageData} from '@data/types';

export interface NavigationProps {
  links: LinkObject[];
  utilLinks?: UtilLink[];
  details: DetailObject[];
  search: string;
}

export interface NavigationBottomProps {
  links: LinkObject[];
  utilLinks?: UtilLink[];
  details: DetailObject[];
  show: boolean;
}

interface DetailObject {
  header?: string;
  body?: string;
  image?: string;
}
interface LinkObject {
  label: string;
  url: string;
  links?: LinkObject[];
}

interface UtilLink {
  label: string;
  url: string;
  _key: string;
}

/*** STYLING ***/
const StyledWrapper = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
`;

export const Navigation = ({
  links,
  details,
  utilLinks,
  search,
}: NavigationProps): JSX.Element => {
  return (
    <StyledWrapper>
      {/** ANNOUNCEMENT BAR GOES HERE? */}
      {/*** MOBILE */}
      <MobileMenu
        links={links}
        details={details}
        utilLinks={utilLinks}
        search={search}
      />
      {/*** DESKTOP */}
      <DesktopMenu
        links={links}
        details={details}
        utilLinks={utilLinks}
        search={search}
      />
    </StyledWrapper>
  );
};
