// TODO: @plat reimplement this component without styled system, and without SC variants, using explicit theme maipulation instead
import {MouseEventHandler} from 'react';
import Link from '@components/Link';
import styled from 'styled-components';
import {variant} from 'styled-system'; // TODO: CRITICAL: remove styled-system calls
import {
  primary,
  primaryOrange,
  primaryBlue,
  primaryGreen,
  secondary,
  secondaryBlack,
  secondaryBlue,
} from '@design/variants/buttons';
import {RightArrowNoLine} from '../Arrow/index';
import {font} from '@design/templateFns';

type ButtonVariants =
  | 'primary'
  | 'primaryOrange'
  | 'primaryBlue'
  | 'primaryGreen'
  | 'secondary'
  | 'secondaryBlack'
  | 'secondaryBlue';

export interface ButtonProps {
  variant: ButtonVariants;
  // For URLs
  url?: string;
  // For click events
  onClickHandler?: MouseEventHandler<HTMLButtonElement>;
  label: string;
  arrow: Boolean;
  arrowColor?: string;
}

/*** STYLING ***/
const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledSpan = styled.span`
  margin-right: 16px;
  ${(props) =>
    (props.font.slice(0, 3) == 'pri' && font('headline-xxs')) ||
    (props.font.slice(0, 3) == 'sec' && font('headline-xs'))}
`;
const StyledButton = styled.button`
  ${variant({
    variants: {
      primary,
      primaryOrange,
      primaryBlue,
      primaryGreen,
      secondary,
      secondaryBlack,
      secondaryBlue,
    },
  })}
`;

const StyledButtonLink = styled.span`
  ${variant({
    variants: {
      primary,
      primaryOrange,
      primaryBlue,
      primaryGreen,
      secondary,
      secondaryBlack,
      secondaryBlue,
    },
  })}
`;

export const Button = ({
  variant = 'primary',
  url,
  onClickHandler,
  label = 'Learn About Us',
  arrow = true,
  arrowColor = 'black',
}: ButtonProps): JSX.Element => {
  return (
    <StyledWrapper>
      {url ? (
        <Link href={url}>
          <StyledButtonLink variant={variant}>
            <StyledSpan font={variant}>{label}</StyledSpan>
            {(arrow || null) && (
              <RightArrowNoLine stroke={arrowColor} size={8} />
            )}
          </StyledButtonLink>
        </Link>
      ) : (
        <StyledButton onClick={onClickHandler} variant={variant}>
          <StyledSpan font={variant}>{label}</StyledSpan>
          {(arrow || null) && <RightArrowNoLine stroke={arrowColor} size={8} />}
        </StyledButton>
      )}
    </StyledWrapper>
  );
};
