//import {default as theme} from './theme';
import type {
  Theme,
  ThemeColorName,
  ThemeSpacingName,
  ThemeFontName,
  ThemeBreakpointName,
} from './theme';
import {default as defaultTheme} from './theme';
import {default as queryObj, mq} from './query';

// basically any given object with a platform-shaped theme
type templateProps = {
  theme: Theme;
  [index: string]: any;
};

// returns color value string
export const color =
  (name: ThemeColorName) =>
  ({theme = defaultTheme, ...props}: templateProps) => {
    return theme.colors[name];
  };

// returns css space value string
export const space =
  (nameOrMultiple: ThemeSpacingName | number) =>
  ({theme = defaultTheme, ...props}: templateProps) => {
    if (theme.spacing[nameOrMultiple]) {
      return `${theme.spacing[nameOrMultiple]}`;
    }
    if (Number.isFinite(nameOrMultiple)) {
      return `calc(${theme.spacing.unit} * ${nameOrMultiple})`;
    }
  };

export const font =
  (name: ThemeFontName) =>
  ({theme = defaultTheme, ...props}: templateProps) => {
    const font = theme.fonts[name];
    return {...font};
  };

// returns css rule objects
export const fontStack =
  (names: ThemeFontName[]) =>
  ({theme = defaultTheme, ...props}: templateProps) => {
    const defs = names.map((name) => theme.fonts[name]);
    return mq({
      //TODO: write a helper to do this kind of pivot on stacks of rules
      fontFamily: defs.map((d) => d.fontFamily),
      fontStyle: defs.map((d) => d.fontStyle),
      fontWeight: defs.map((d) => d.fontWeight),
      fontSize: defs.map((d) => d.fontSize),
      lineHeight: defs.map((d) => d.lineHeight),
      letterSpacing: defs.map((d) => d.letterSpacing),
    });
  };

const atLeast =
  (breakpoint: ThemeBreakpointName) =>
  ({theme = defaultTheme, ...props}: templateProps) =>
    queryObj.atLeast[breakpoint];

const below =
  (breakpoint: ThemeBreakpointName) =>
  ({theme = defaultTheme, ...props}: templateProps) =>
    queryObj.below[breakpoint];

export const query = {
  atLeast,
  below,
};
